import { get, post, TResult } from "./BaseApi";

export type TTab = "all" | "todo" | "tosend" | "pending" | "limbo" | "reviewed";

export interface IReview {
    ASIN: string;
    requested_date: string;
    name: string;
    review_stars: number;
    review_title: string;
    review_text: string;
    review_status: number;
    review_status_name: string;
    user_id: number;
    user_name: string;
}

export interface IReviewStatus {
    id: number;
    name: string;
}

interface IList {
    list: IReview[];
    quantity: number;
}

interface IStatusList {
    list: IReviewStatus[];
}

class ReviewAPI {
    public async List(
        page: number,
        user: number | null,
        status: number,
        searchTerm: string
    ): Promise<IList> {
        const treatedSearchTerm = searchTerm ? `%${searchTerm}%` : "";
        const apiResult = await get<TResult<IList>>(
            `review?status=${status}&user=${user === null ? "" : user}&page=${
                page === null ? "" : page
            }&searchterm=${treatedSearchTerm}`
        );

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: [],
                quantity: 0
            };
        }
    }

    public async ListStatus(): Promise<IStatusList> {
        const apiResult = await get<TResult<IStatusList>>(`review/status`);

        if (apiResult.status === 200) {
            return apiResult.data.result;
        } else {
            return {
                list: []
            };
        }
    }

    public async UpdateStatus(ASIN: string, status: number): Promise<boolean> {
        const apiResult = await post<TResult<boolean>>(`review/${ASIN}/updateStatus`, { status });

        if (apiResult.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    public async MarkAsSubmitted(ASIN: string): Promise<boolean> {
        const apiResult = await post<TResult<boolean>>(`review/${ASIN}/markAsSubmitted`, {});

        if (apiResult.status === 200) {
            return true;
        } else {
            return false;
        }
    }
}

export const Review = new ReviewAPI();
