import styles from "./nav.module.scss";
import { Credentials } from "../../Store/Credentials";
import { slide as Menu } from "react-burger-menu";
import menuStyles from "./menu.styles";
import classNames from "classnames";

const Nav = () => {
    const handleClick = async () => {
        Credentials.LogOut();
    };

    return (
        <div className={styles["nav"]}>
            <Menu pageWrapId={"page-wrap"} right styles={menuStyles}>
                <a id="orders" className={styles["menu-item"]} href="/orders">
                    Orders
                </a>
                <a id="reviews" className={styles["menu-item"]} href="/reviews">
                    Reviews
                </a>
                <a
                    onClick={handleClick}
                    className={classNames(styles["menu-item"], styles["bottom"])}
                    href="/"
                >
                    Logout
                </a>
            </Menu>
        </div>
    );
};

export default Nav;
